<template>
  <div id="register">
    <LoadingImg v-if="loading" />
    <div class="ol-login">
      <div class="ol-popup-box__login">
        <h2 class="ol-popup-box__login-logo">
          <router-link to="/">
            <img src="@/assets/images/morelogo_nd.png" alt="MORE FURNITURE" title="MORE FURNITURE">
          </router-link>
        </h2>

        <div class="ol-popup-box__login-type">
          <Alert
            v-show="alert.messages != null"
            :type="alert.type"
            :collection="alert.messages"
          ></Alert>
          <div
            class="ol-popup-box__form ol-popup-box__login-type-form show add-fix"
            id="login2"
          >
          <form v-on:submit.prevent="register()">
            <div class="add-fix" id="daftar">
              <div class="ol-input-box ol-input-box--full">
                <label class="ol-input-label">Masukan Email Anda</label>
                <input id="email" type="email" class="ol-input form-control" name="email" v-model="pwd.email" required autocomplete="email" autofocus />
              </div>
              <div class="ol-input-box ol-input-box--full">
                <label class="ol-input-label">Kata Sandi Baru</label>
                <Password style="max-width:1000px!important" v-model="pwd.new_password" :badge="false" :toggle="true" @score="showScore" />
              </div>
              <div class="ol-input-box ol-input-box--full">
                <label class="ol-product-content__sidebar-item-list-item-link">
                  <input class="ol-input--radio-check" v-model="mengikuti" name="checkbox2" type="checkbox" />
                  Beritahu saya newsletter atau buletin
                  <i class="ol-input--radio-checkmark dt-input--radio-checkmark--radio" ></i>
                </label>
                <label class="ol-product-content__sidebar-item-list-item-link">
                  <input class="ol-input--radio-check" v-model="setuju" id="checksnk" name="checkbox" v-on:click="cek_box()" type="checkbox" />
                  Saya menyetujui syarat dan ketentuan
                  <i class="ol-input--radio-checkmark dt-input--radio-checkmark--radio" ></i>
                </label>
              </div>

              <input class="ol-input--radio-check" id="snk" name="snk" value="0" type="hidden" />
              <div class="ol-input-box ol-input-box--full">
                <button type="submit" id="submit_daftar" class="ol-btn" disabled>
                  Daftar
                </button>
              </div>
            </div>
          </form>
            <p class="ol-popup-box__login-link">
              Sudah memiliki akun? <router-link to="/login">Masuk Sekarang</router-link>
            </p>

            <div class="ol-popup-box__login-api">
              <p>Atau masuk dengan akun media sosial anda</p>
              <div class="ol-popup-box__login-api-btn">
                <a
                  href="javascript:void(0)"
                  class="ol-btn ol-btn--secondary"
                  v-on:click="loginGoogle()"
                  ><i class="ol-fa fab fa-google"></i> Masuk dengan Google</a
                >
                <!-- <a
                  href="javascript:void(0)"
                  class="ol-btn ol-btn--secondary"
                  v-on:click="loginFb()"
                  ><i class="ol-fa fab fa-facebook-f"></i> Masuk dengan
                  Facebook</a
                > -->
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <a href="javascript:void(0)" class="ol-totop"
      ><i class="ol-fa fas fa-arrow-up"></i
    ></a>
  </div>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
import { initFbsdk } from "../../lib/facebook";
import Password from "vue-password-strength-meter";
export default {
  name: "register",
  metaInfo() {
      return{
          meta: [
              { name: 'title', content: this.currentMeta ? this.currentMeta.meta_title : ""},
              { name: 'description', content: this.currentMeta ? this.currentMeta.meta_desc : ""},
              
          ]
      }
  },
  components: {
    Password,
  },
  data() {
    return {
      pwd: {
        email: null,
        new_password: null,
      },
      alert: {
        messages: { email: ["Kami akan mengirimkan link ke email Anda"] },
        message: null,
        type: "info",
      },
      score: 0,
      setuju: false,
      mengikuti: false,
      loading: false,
    };
  },
  computed:{
    ...mapGetters("auth", ["user"]),
		...mapGetters("home", ["metaPages"]),
  },
  
  methods: {
    async getUser() {
      let user = await this.fetchUser();
      if (user.statusCode == 200) {
        if (user.data.email && user.data.email != "") {
          this.$router.push("/");
        } else {
          this.$router.push("/profile");
        }
      } else {
        this.error = user.data.message;
      }
    },
    ...mapActions("auth", [
      "registerUser",
      "fetchUser",
      "loginUserGoogle",
      "loginUserFb",
    ]),
    showScore(score) {
      this.score = score;
    },
    cek_box() {
      if (this.setuju) {
        $("#submit_daftar").prop("disabled", true);
      } else {
        $("#submit_daftar").prop("disabled", false);
      }
    },
    async register() {
      if (this.score >= 1) {
        if (this.pwd.email != "" && this.pwd.password != "" && this.setuju) {
          this.loading = true;
          var formdata = new FormData();
          formdata.append("email", this.pwd.email);
          formdata.append("password", this.pwd.password);
          formdata.append("is_subscribe", this.mengikuti ? 1 : 0);
          let result = await this.registerUser(formdata);
          if (result.statusCode == 200) {
            // this.analytics('CompleteRegistration');
            fbq('track', 'CompleteRegistration', {
              content_name:"Registration",
              currency:"IDR",
              status:true,
              value:this.pwd.email
            });
            (this.messages = {
              email: ["Email verifikasi telah dikirim ke email Anda"],
            }),
              (this.alert.type = "success");
            this.loading = false;
            this.getUser();
          } else {
            this.alert.messages = result.data;
            this.alert.type = "danger";
            this.loading = false;
          }
        } else {
          this.alert.messages = { email: ["Email dan password harus diisi."] };
          this.alert.type = "danger";
        }
      } else {
        this.alert.messages = {
          pwd: ["Kekuatan kata sandi minimal medium (memenuhi 2 bar)"],
        };
        this.alert.type = "danger";
      }
    },
    loginGoogle() {
      this.$gAuth
        .signIn()
        .then(async (GoogleUser) => {
          this.loading = true;
          var formdata = new FormData();
          formdata.append('email', GoogleUser.getBasicProfile().getEmail());
          formdata.append('name', GoogleUser.getBasicProfile().getName());
          formdata.append('picture', GoogleUser.getBasicProfile().getImageUrl());
          formdata.append('uuid', GoogleUser.getBasicProfile().getId());
          let result = await this.loginUserGoogle(formdata);

          if (result.statusCode == 200) {
            // this.analytics('CompleteRegistration');
            this.getUser();
          } else {
            var err = "";
            if (result.data.email) {
              err += result.data.email + "\n";
            }
            if (result.data.message) {
              err += result.data.message;
            }
            this.error = err;
            this.loading = false;
          }
          // console.log(result);
        })
        .catch((error) => {
          // console.log("error", error);
        });
    },
    loginFb() {
      window.FB.login(
        async (response) => {
          if (response.authResponse) {
            this.loading = true;
            await window.FB.api(
              "/me",
              {
                fields: "name,gender,location,picture,email",
                access_token: response.authResponse.accessToken,
              },
              async (response) => {
                // console.log(response);
                // console.log(response.name + ", " + response.email);
                var formdata = new FormData();
                formdata.append("email", response.email);
                formdata.append("name", response.name);
                formdata.append("picture", response.picture.data.url);
                formdata.append("uuid", response.id);
                let result = await this.loginUserFb(formdata);
                if (result.statusCode == 200) {
                  // this.analytics('CompleteRegistration');
                  this.getUser();
                } else {
                  var err = "";
                  if (result.data.email) {
                    err += result.data.email + "\n";
                  }
                  if (result.data.message) {
                    err += result.data.message;
                  }
                  this.error = err;
                  this.loading = false;
                }
              }
            );
          }
        },
        { scope: "email" }
      );
    },
		...mapActions("home", ["fetchMetaPages"]),
    async getMeta(){
        await this.fetchMetaPages();
        this.currentMeta = _.find(this.metaPages, {"slug":"more-register"});
    },
  },
  created(){
      this.getMeta();
  },
  updated(){
      this.getMeta();
  },
  mounted () {
      this.getMeta();
      initFbsdk()
  },
};
</script>
